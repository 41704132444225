import React from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import { appConfigStore } from "../app/stores/AppConfig";

export const AppLogo = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const heroImage = darkMode
		? require("../assets/images/logo-solo.png")
		: require("../assets/images/logo-solo.png");

	return (
		<View
			style={{
				alignItems: "center",
				marginTop: 12,
			}}
		>
			<Image
				style={styles.heroImage}
				source={heroImage}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	heroImage: {
		width: 200,
		height: 120,
		resizeMode: "contain",
		tintColor: "white",
	},
});
