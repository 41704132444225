import { Grid, Typography } from "@mui/material";
import React from "react";
import { StyleSheet } from "react-native";

export const Footer = () => {
	return (
		<Grid
			container
			style={styles.imageBackground}
			minHeight={{ sm: 140, md: 220 }}
			p={4}
			justifyContent={"center"}
			alignContent={"flex-end"}
		>
			<Grid
				item
				style={styles.sectionContainer}
				alignSelf={"flex-end"}
				display={{ xs: "none", md: "flex" }}
			>
				<Typography
					variant="h6"
					style={styles.footerTextMain}
				>
					BBEEE COMPLIANT
				</Typography>
				<Typography style={styles.footerTextSecondary}>
					Schwin Logistic is a Level 1 BBEEE Contributor.
				</Typography>
				<Typography style={styles.footerTextSecondary}>
					A copy of our certificate is available on request.
				</Typography>
			</Grid>
			<Grid
				item
				style={styles.sectionContainer}
				textAlign="center"
				alignSelf={"flex-end"}
			>
				<Typography style={styles.footerTextSecondary}>
					© Schwin Logistics. All Rights Reserved
				</Typography>
				<Typography style={styles.footerTextSecondary}>
					Designed by Twisted Studios
				</Typography>
			</Grid>
			<Grid
				item
				style={styles.sectionContainer}
				alignSelf={"flex-end"}
				textAlign={"right"}
				display={{ xs: "none", md: "flex" }}
			>
				<Typography
					variant="h6"
					style={styles.footerTextMain}
				>
					QUICK CONTACT
				</Typography>
				<Typography style={styles.footerTextSecondary}>Tel: 084 620 9118</Typography>
				<Typography style={styles.footerTextSecondary}>swantish@schwin.co.za</Typography>
				<Typography style={styles.footerTextSecondary}>Durban, South Africa</Typography>
			</Grid>
		</Grid>
	);
};

const styles = StyleSheet.create({
	imageBackground: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		paddingHorizontal: 60,
		paddingVertical: 18,
		alignSelf: "center",
		backgroundColor: "#111111",
	},
	sectionContainer: {
		flex: 4,
		flexDirection: "column",
	},
	heroImage: {
		width: 200,
		height: 120,
		resizeMode: "contain",
	},
	footerTextMain: {
		marginVertical: 8,
		letterSpacing: 0.5,
		fontSize: 13,
		fontWeight: "900",
		fontFamily: "secondaryFont",
		color: "white",
	},
	footerTextSecondary: {
		marginVertical: 2,
		letterSpacing: 0.5,
		fontSize: 12,
		fontWeight: "600",
		fontFamily: "secondaryFont",
		color: "gray",
	},
});
