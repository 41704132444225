import React, { useEffect, useState } from "react";
import { Animated, StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import { registerRootComponent } from "expo";
import { useFonts } from "expo-font";
import { DefaultTheme, LinkingOptions, NavigationContainer } from "@react-navigation/native";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { HomeStack } from "../navigation/HomeStack";
import { PrimaryColor, AccentColor } from "../utils/Constants";
import { navigationStore } from "./stores/Navigation";

const App = () => {
	const splashLoaded = navigationStore((state) => state.splashLoaded);
	const splashVideoLoaded = navigationStore((state) => state.splashVideoLoaded);
	const updateSplashVideoLoaded = navigationStore((state) => state.updateSplashVideoLoaded);
	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loadingFadeAnimation] = useState(new Animated.Value(1));
	const [loadingZIndex] = useState(new Animated.Value(0));

	const [fontsLoaded] = useFonts({
		primaryFont: require("../assets/fonts/orpheuspro-medium.otf"),
		secondaryFont: require("../assets/fonts/neuehaas-roman.ttf"),
		logoFont: require("../assets/fonts/copperplate-gothic.ttf"),
	});

	const loadingFadeAway = () => {
		Animated.parallel([
			Animated.timing(loadingFadeAnimation, {
				toValue: 0,
				duration: 1250,
				delay: 1000,
				useNativeDriver: false,
			}),
			Animated.timing(fadeAnimation, {
				toValue: 1,
				duration: 1250,
				delay: 500,
				useNativeDriver: false,
			}),
			Animated.timing(loadingZIndex, {
				toValue: -1,
				duration: 1250,
				delay: 1000,
				useNativeDriver: false,
			}),
		]).start();
	};

	useEffect(() => {
		setTimeout(() => {
			updateSplashVideoLoaded(true);
		}, 5000);
	}, []);

	useEffect(() => {
		if (splashVideoLoaded && fontsLoaded) {
			loadingFadeAway();
		}
	}, [splashVideoLoaded, fontsLoaded]);

	const VideoBackground = () => {
		return (
			<>
				<video
					playsInline
					autoPlay
					loop
					muted
					style={styles.video}
					src={require("../assets/videos/background-video.mp4")}
					onLoadedData={() => updateSplashVideoLoaded(true)}
				/>
				<View
					style={{
						...styles.overlay,
						// backgroundColor: !darkMode ? "rgba(17, 17, 17, 0.75)" : "rgba(255, 255, 255, 0.75)",
						backgroundColor: PrimaryColor,
						opacity: "90%",
					}}
				/>
			</>
		);
	};

	const Container = () => {
		const linking: LinkingOptions<ReactNavigation.RootParamList> = {
			config: {
				screens: {
					About_Us: "aboutus",
					Contact_Us: "contactus",
					Our_Fleet: "ourfleet",
					NotFound: "*",
				},
			},
			prefixes: ["schwin-logistics://"],
		};

		const navigatorTheme = {
			...DefaultTheme,
			colors: {
				...DefaultTheme.colors,
				background: "transparent",
			},
		};

		return (
			<NavigationContainer
				linking={linking}
				theme={navigatorTheme}
			>
				<Animated.View
					style={{
						...styles.container,
						...{ opacity: "100%" },
					}}
				>
					<HomeStack />
				</Animated.View>
			</NavigationContainer>
		);
	};

	const LoadingView = () => {
		return (
			<Animated.View
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					backgroundColor: PrimaryColor,
					justifyContent: "center",
					alignItems: "center",
					opacity: loadingFadeAnimation,
					zIndex: loadingZIndex,
				}}
			>
				<Image
					style={styles.heroImage}
					source={require("../assets/images/logo-solo.png")}
				/>
				<ActivityIndicator
					color={AccentColor}
					size={"large"}
				/>
			</Animated.View>
		);
	};

	return (
		<View style={{ width: "100%", height: "100%" }}>
			{!splashLoaded && <VideoBackground />}
			<Container />
			<LoadingView />
		</View>
	);
};

registerRootComponent(App);
serviceWorkerRegistration.register();

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
	},
	video: {
		position: "absolute",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
	heroImage: {
		width: 200,
		height: 200,
		resizeMode: "contain",
		tintColor: "white",
	},
	title: {
		alignSelf: "center",
		fontSize: 18,
		marginBottom: 18,
		fontWeight: "800",
		fontFamily: "logoFont",
		color: "white",
	},
});
