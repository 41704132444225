import React from "react";
import { StyleSheet, Text } from "react-native";
import { Button } from "@mui/material";
import { AccentColor } from "../utils/Constants";

interface Props {
	onClick?: () => void;
	title: string;
	style?: {};
	variant?: "text" | "contained" | "outlined";
	type?: "submit" | "reset" | "button";
	sx?: {};
	disabled?: boolean;
}

export const BasicButton = ({
	onClick = () => {},
	title,
	style,
	variant = "contained",
	type = "button",
	sx = {},
	disabled = false,
}: Props) => {
	return (
		<Button
			type={type}
			sx={sx}
			style={{ ...styles.button, ...style }}
			variant={variant}
			onClick={onClick}
			disabled={disabled}
		>
			<Text
				style={{
					...styles.text,
					...{
						color: style?.color ?? styles.text.color,
						fontSize: style?.fontSize ?? styles.text.fontSize,
					},
				}}
			>
				{title}
			</Text>
		</Button>
	);
};

const styles = StyleSheet.create({
	button: {
		height: 45,
		borderRadius: 30,
		backgroundColor: AccentColor,
	},
	text: {
		fontWeight: "900",
		fontSize: 11,
		letterSpacing: 0.5,
		fontFamily: "secondaryFont",
		color: "white",
	},
});
