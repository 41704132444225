import { Divider, Grid } from "@mui/material";
import React from "react";
import { Image } from "react-native";
import { AccentColor } from "../utils/Constants";

interface Props {
	headerIconName: string;
}
export const HeaderIcon = ({ headerIconName }: Props) => {
	return (
		<Grid>
			<Grid>
				<Image
					style={{ width: 45, aspectRatio: 1 / 1, margin: 12 }}
					source={require(`../assets/images/icons/${headerIconName}.png`)}
				/>
			</Grid>
			<Grid marginX={3}>
				<Divider
					style={{ height: 2 }}
					orientation="horizontal"
					color={AccentColor}
				/>
			</Grid>
		</Grid>
	);
};
