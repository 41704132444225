import { useState } from "react";

const useEmailJs = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const sendEmailForm = async (formData: FormData) => {
		formData.append("service_id", "service_v169h8e");
		formData.append("template_id", "template_87bt0qg");
		formData.append("user_id", "Dsk1gtOQPI-7-oKNa");

		setLoading(true);

		try {
			const response = await fetch("https://api.emailjs.com/api/v1.0/email/send-form", {
				method: "POST",
				body: formData,
			});

			if (!response.ok) {
				throw new Error(`Failed to send email: ${response.status} ${response.statusText}`);
			}

			setLoading(false);
			setError(null);
			// You can handle the success response here if needed

			console.log("email sent");
			console.log(response.json());
			return response.json();
		} catch (error) {
			setLoading(false);
			setError(error.message);
			// You can handle the error here if needed
		}
	};

	return { sendEmailForm, loading, error };
};

export default useEmailJs;
