import React, { useEffect, useState } from "react";
import {
	View,
	Image,
	Text,
	StyleSheet,
	Animated,
	TouchableHighlight,
	ImageBackground,
} from "react-native";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { NavBarItem } from "./NavBarItem";
import { Divider, useAutocomplete } from "@mui/material";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { navigationStore } from "../../../app/stores/Navigation";
import { BasicButton } from "../../BasicButton";
import { useLinkTo } from "@react-navigation/native";
import {
	AccentColor,
	FastAnimationTime,
	NavBarHeight,
	PrimaryColor,
} from "../../../utils/Constants";

interface NavBarProps {
	screens: ScreenComponent[];
	align?: "center" | "flex-start" | "flex-end";
}

export const NavBar = ({ screens, align = "center" }: NavBarProps) => {
	const linkTo = useLinkTo();

	const darkMode = appConfigStore((state) => state.darkMode);
	const enabledNavBg = navigationStore((state) => state.enabledNavBg);

	const [navHeightAnimation] = useState(new Animated.Value(0));
	const [bgColorAnimation] = useState(new Animated.Value(0));

	useEffect(() => {
		navBarOpacityChange();
	}, []);

	useEffect(() => {
		if (enabledNavBg) slideDown();
	}, [enabledNavBg]);

	const navBarOpacityChange = () => {
		Animated.timing(bgColorAnimation, {
			toValue: enabledNavBg ? 1 : 0,
			duration: FastAnimationTime,
			useNativeDriver: false,
		}).start();
	};

	const slideDown = () => {
		Animated.timing(navHeightAnimation, {
			toValue: NavBarHeight,
			duration: FastAnimationTime,
			useNativeDriver: false,
		}).start();
	};

	const slideUp = () => {
		Animated.timing(navHeightAnimation, {
			toValue: 0,
			duration: FastAnimationTime,
			useNativeDriver: false,
		}).start();
	};

	const NavigationToScreen = (screen: ScreenComponent) => {
		console.log("navigating -> " + screen.toLowerCase().replaceAll("_", ""));
		linkTo(`/${screen.toLowerCase().replaceAll("_", "")}`);
		nav.updateCurrentScreen(screen);
	};

	return (
		<Animated.View
			style={{
				marginTop: navHeightAnimation.interpolate({
					inputRange: [0, NavBarHeight],
					outputRange: [-NavBarHeight, 0],
				}),
				height: NavBarHeight,
				maxHeight: NavBarHeight,
				overflow: "hidden", // Clip the content when navbar is collapsed
				// opacity: bgColorAnimation,
				// borderBottomWidth: 3,
				// borderBottomColor: "purple",
				// backgroundColor: darkMode ? "#111111" : "#fcfcfc",
				backgroundColor: PrimaryColor,
			}}
		>
			{/* <Animated.View
				style={{
					width: "100%",
					height: NavBarHeight,
					position: "absolute",
					top: 0,
					opacity: bgColorAnimation,
				}}
			>
				<Image
					source={require("../../../assets/images/logo-solo.png")}
					style={{
						width: 95,
						height: 95,
						tintColor: "white",
					}}
				/>
			</Animated.View> */}

			<View
				style={{
					flex: 1,
					flexDirection: "row",
					justifyContent: align,
					paddingHorizontal: 40,
					paddingVertical: 18,
				}}
			>
				<TouchableHighlight
					style={{ position: "absolute", left: 30, top: 10 }}
					underlayColor={"transparent"}
					onPress={() => NavigationToScreen(ScreenComponent.AboutUs)}
				>
					<View
						style={{
							flexDirection: "row",
						}}
					>
						<Image
							source={require("../../../assets/images/logo-solo.png")}
							style={{
								width: 95,
								height: 95,
								tintColor: "white",
							}}
						/>
						{/* <Divider
							color={darkMode ? "white" : "black"}
							orientation="vertical"
							style={{
								marginLeft: 8,
								height: 16,
								alignSelf: "center",
							}}
						/> */}
						{/* <View
							style={{
								flex: 1,
								alignSelf: "center",
							}}
						>
							<Text style={{ ...styles.title, alignSelf: "flex-start" }}>SCHWIN</Text>
							<Text style={{ ...styles.title, alignSelf: "flex-start" }}>LOGISTICS</Text>
						</View> */}
					</View>
				</TouchableHighlight>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center", // align vertical
						justifyContent: align,
						marginTop: 8,
					}}
				>
					{screens.map((screen, index) => {
						return (
							<NavBarItem
								key={index}
								screen={screen}
							/>
						);
					})}
				</View>
			</View>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 100,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	title: {
		marginLeft: 20,
		alignSelf: "center",
		fontSize: 18,
		fontWeight: "800",
		fontFamily: "logoFont",
		color: "white",
	},
});
