import {
	DrawerContentScrollView,
	DrawerItem,
	createDrawerNavigator,
} from "@react-navigation/drawer";
import { useLinkTo } from "@react-navigation/native";
import React from "react";
import { useWindowDimensions } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { AccentColor, PrimaryColor } from "../../../utils/Constants";
import Header from "../../Header";
import { NavScreenComponent } from "../NavScreenComponent";

const Drawer = createDrawerNavigator();

export const DrawerNavigation = () => {
	const nav = navigationStore((state) => state);
	const darkMode = appConfigStore((state) => state.darkMode);
	const linkTo = useLinkTo();
	const { width } = useWindowDimensions();

	const CustomDrawerContent = (props) => {
		const NavigationToScreen = (screen: ScreenComponent) => {
			linkTo(`/${screen.toLowerCase().replaceAll("_", "") as never}`);
			nav.updateCurrentScreen(screen as ScreenComponent);
		};

		return (
			<DrawerContentScrollView {...props}>
				<Header enableDivider={false} />
				{nav.screenList.map((screen, index) => {
					let isCurrentScreen = nav.currentScreen === screen;
					return (
						<DrawerItem
							key={"item_" + index}
							label={screen.replaceAll("_", " ")}
							labelStyle={{
								fontSize: 16,
								fontWeight: "600",
								fontFamily: "secondaryFont",
								paddingLeft: 14,
								textAlign: "center",
								color: "white",
							}}
							style={{
								backgroundColor: isCurrentScreen ? AccentColor : "transparent",
							}}
							onPress={() => NavigationToScreen(screen)}
						/>
					);
				})}
			</DrawerContentScrollView>
		);
	};

	return (
		<Drawer.Navigator
			useLegacyImplementation
			defaultStatus="closed"
			initialRouteName={nav.currentScreen.toString()}
			drawerContent={(props) => <CustomDrawerContent {...props} />}
			screenOptions={{
				drawerType: "slide",
				headerShown: !nav.splashLoaded ? false : true,
				headerTintColor: darkMode ? "#fcfcfc" : "#111111",
				headerTransparent: false,
				headerTitleAlign: "center",
				headerTitleStyle: {
					letterSpacing: 1,
					fontSize: 16,
					fontWeight: "600",
					fontFamily: "secondaryFont",
				},
				headerBackgroundContainerStyle: {},
				drawerStyle: {
					backgroundColor: PrimaryColor,
					width: "75%",
				},
				headerStyle: {
					backgroundColor: PrimaryColor,
					borderBottomColor: AccentColor,
				},
			}}
		>
			{nav.screenList.map((screen, index) => {
				return (
					<Drawer.Screen
						key={"screen_" + index}
						name={screen.toString()}
						component={NavScreenComponent(screen)}
						// initialParams={{ screenName: screen }}
						options={{
							title: `${screen.replaceAll("_", " ").toString()}`, //`Schwin Logistics`,
							drawerLabel: screen.replaceAll("_", " ").toString(),
						}}
					/>
				);
			})}
		</Drawer.Navigator>
	);
};
