import { ContactUs } from "../../screens/Main/ContactUs";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { AboutUs } from "../../screens/Main/AboutUs";
import { OurFleet } from "../../screens/Main/OurFleet";

export const NavScreenComponent = (screen: ScreenComponent) => {
	switch (screen) {
		case ScreenComponent.AboutUs:
			return AboutUs;
		case ScreenComponent.OurFleet:
			return OurFleet;
		case ScreenComponent.Contact:
			return ContactUs;

		default:
			throw new Error(`Unsupported screen component: ${screen}`);
	}
};
