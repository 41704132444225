import React from "react";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { TouchableHighlight } from "react-native-gesture-handler";
import { Text } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { useLinkTo } from "@react-navigation/native";
import { AccentColor } from "../../../utils/Constants";

interface NavBarItem {
	screen: ScreenComponent;
}

export const NavBarItem = ({ screen }: NavBarItem) => {
	const linkTo = useLinkTo();
	const currentScreen = navigationStore((state) => state.currentScreen);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const darkMode = appConfigStore((state) => state.darkMode);

	const isCurrentScreen = currentScreen === screen;

	const NavigationToScreen = (screen: ScreenComponent) => {
		linkTo(`/${screen.toLowerCase().replaceAll("_", "") as never}`);
		updateCurrentScreen(screen as ScreenComponent);
	};

	return (
		<TouchableHighlight
			style={{
				minHeight: 32,
				paddingHorizontal: 10,
				marginHorizontal: 18,
				paddingBottom: 8,
				justifyContent: "center",
				borderBottomColor: isCurrentScreen ? (darkMode ? AccentColor : AccentColor) : "transparent",
				borderBottomWidth: 3,
				borderRadius: 1,
			}}
			underlayColor="transparent"
			onPress={() => NavigationToScreen(screen)}
		>
			<Text
				style={{
					letterSpacing: 0.5,
					fontSize: 16,
					fontWeight: "600",
					fontFamily: "secondaryFont",
					textAlign: "center",
					color: darkMode ? "#fcfcfc" : "#111111",
				}}
			>
				{screen.replaceAll("_", " ").toString()}
			</Text>
		</TouchableHighlight>
	);
};
