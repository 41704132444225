export const TitleFontSize = {
	fontSize: 38,
	fontWeight: "400",
	fontFamily: "orpheus-pro",
};

export const SecondaryFontSize = {
	fontWeight: "300",
	fontSize: 18,
	fontFamily: "neue-haas",
	lineHeight: 32,
};

export const ButtonText = {
	fontWeight: "600",
	fontSize: 18,
	color: "white",
	fontFamily: "primaryFont",
};

export const PrimaryColor = "#540070";
export const AccentColor = "#F17A00";
export const NavBarHeight = 120;
export const FastAnimationTime = 700;
export const SlowAnimationTime = 1000;
export const DelayAnimationTime = 300;
