import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const BasicTextField = withStyles({
	root: {
		"& label": {
			color: "black",
		},
		"& label.Mui-focused": {
			color: "black",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "black",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black",
			},
			"&:hover fieldset": {
				borderColor: "black",
			},
			"&.Mui-focused fieldset": {
				borderColor: "black",
			},
		},
		"& .MuiInputBase-input": {
			color: "black",
			fontSize: 16,
			fontFamily: "secondaryFont",
		},
		"& .MuiInputLabel-root": {
			color: "grey",
			fontSize: 16,
			fontFamily: "secondaryFont",
		},
	},
})(TextField);
