import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Animated, Image } from "react-native";
import { Container, Grid, Box } from "@mui/material";
import ImageList from '@mui/material/ImageList'; // Ensure correct import
import ImageListItem from '@mui/material/ImageListItem'; // Ensure correct import
import { SlowAnimationTime } from "../../utils/Constants";
import { Footer } from "../../components/Footer";
import { navigationStore } from "../../app/stores/Navigation";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { HeaderIcon } from "../../components/HeaderIcon";
import { isLarge } from "../../navigation/HomeStack";

export const OurFleet = () => {
	const updateEnabledNavBg = navigationStore((state) => state.updateEnabledNavBg);
	const updateSplashLoaded = navigationStore((state) => state.updateSplashLoaded);
	const updateSplashVideoLoaded = navigationStore((state) => state.updateSplashVideoLoaded);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const [bgColorAnimation] = useState(new Animated.Value(0));

	useEffect(() => {
		fadeInWhiteBackground();
		updateEnabledNavBg(true);
		updateSplashLoaded(true);
		updateSplashVideoLoaded(true);
		updateCurrentScreen(ScreenComponent.OurFleet);
	}, []);

	const fadeInWhiteBackground = () => {
		Animated.timing(bgColorAnimation, {
			toValue: 1,
			duration: SlowAnimationTime,
			useNativeDriver: false,
		}).start();
	};

	const itemData = Array.from({ length: 23 }, (_, index) => ({
		img: require(`../../assets/images/gallery/${index + 1}.jpg`),
		title: `Image ${index + 1}`
	}));

	const Content = () => {
		return (
			<Container
				component="main"
				maxWidth="md"
			>
				<Grid
					container
					alignItems="center"
					flexDirection="column"
					spacing={2}
					mt={10}
				>
					<HeaderIcon headerIconName={"semi"} />
					<Grid
						item
						xs={12}
						textAlign="center"
					>
						<View style={{ flex: 1, flexDirection: "column" }}>
							<Text style={styles.title}>Our Fleet</Text>
							<Text style={[styles.subtitle, { color: "gray" }]}>
								Our fleet consists of UD QUON 460 Trucks
							</Text>
						</View>
					</Grid>
					<Grid mb={4}>
						<Text style={styles.subtitle}>Safety features on each vehicle:</Text>
					</Grid>
				</Grid>
			</Container>
		);
	};

	const SafetyFeatures = () => {
		const serviceItems = [
			{
				icon: "shield",
				title: "Container Locking Mechanisms",
			},
			{
				icon: "cctv-camera",
				title: "Cameras with Live Vision and Audio on Each Truck",
			},
			{
				icon: "tracking",
				title: "3 Live Tracking Devices on Horse and Trailer",
			},
			{
				icon: "protection",
				title: "All Trailers Fitted with Recovery Units",
			},
		];

		const renderServiceItem = (item, index) => (
			<Grid
				key={index}
				item
				container
				xs={11}
				md={2}
				border={2}
				borderRadius={2}
				borderColor="#f1f1f1"
			>
				<Grid
					item
					container
					direction="column"
					alignItems="center"
					padding={4}
				>
					<Grid
						item
						mb={2}
					>
						<HeaderIcon headerIconName={item.icon} />
					</Grid>
					<Grid
						item
						style={{ textAlign: "center" }}
					>
						<Text style={styles.serviceTitle}>{item.title}</Text>
					</Grid>
				</Grid>
			</Grid>
		);

		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				my={4}
			>
				<Grid
					container
					flexDirection="row"
					justifyContent="center"
					alignContent={"center"}
					gap={2}
				>
					{serviceItems.map((item, index) => renderServiceItem(item, index))}
				</Grid>
			</Grid>
		);
	};

	const Gallery = () => {
		const [imageAnimations, setImageAnimations] = useState(itemData.map(() => new Animated.Value(0)));

		const handleImageLoad = (index) => {
			Animated.timing(imageAnimations[index], {
				toValue: 1,
				duration: 1000, // Adjust duration as needed
				useNativeDriver: true,
			}).start();
		};

		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				my={4}
				mb={12}
			>
				<Grid mb={4}>
					<Text style={styles.title}>Gallery</Text>
				</Grid>
				<Grid>
					<Box>
						<ImageList
							variant="masonry"
							cols={isLarge() ? 3 : 2}
							gap={10}
							sx={{ paddingX: isLarge() ? 18 : 4, flexDirection: "column" }}
						>
							{
								itemData.map((item, index) => (
									<ImageListItem key={item.img}>
										<Animated.View style={{ opacity: imageAnimations[index] }}>
											<img
												src={item.img}
												style={{
													borderRadius: 10,
													width: '100%',
													height: 'auto',
												}}
												onLoad={() => handleImageLoad(index)}
												loading="lazy"
											/>
										</Animated.View>
									</ImageListItem>
								))
							}
						</ImageList>
					</Box>
				</Grid>
			</Grid>
		);
	};

	return (
		<Animated.ScrollView
			style={[
				{
					backgroundColor: bgColorAnimation.interpolate({
						inputRange: [0, 1],
						outputRange: ["rgba(255, 255, 255, 0.0)", "rgba(255, 255, 255, 1)"],
					}),
				},
			]}
			contentContainerStyle={styles.container}
		>
			<Animated.View>
				<Content />
				<SafetyFeatures />
				<Gallery />
				<Footer />
			</Animated.View>
		</Animated.ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1, 
		flexDirection: "column",
		paddingBottom: 180,
	},
	title: {
		fontSize: 46,
		fontWeight: "400",
		fontFamily: "primaryFont",
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 18,
		fontWeight: "300",
		fontFamily: "secondaryFont",
		lineHeight: 32,
		textAlign: "center",
		color: "gray",
	},
});
