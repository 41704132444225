import React, { useEffect, useState } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	Animated,
	ActivityIndicator,
	Image,
	Linking,
} from "react-native";
import { Container, Box, Grid, Divider } from "@mui/material";
import { BasicButton } from "../../components/BasicButton";
import { BasicTextField } from "../../components/BasicTextField";
import { FastAnimationTime, SlowAnimationTime } from "../../utils/Constants";
import { Footer } from "../../components/Footer";
import { navigationStore } from "../../app/stores/Navigation";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import useEmailJs from "../../services/useEmail";

export const ContactUs = () => {
	const updateEnabledNavBg = navigationStore((state) => state.updateEnabledNavBg);
	const updateSplashLoaded = navigationStore((state) => state.updateSplashLoaded);
	const updateSplashVideoLoaded = navigationStore((state) => state.updateSplashVideoLoaded);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const [mapsLoaded, setMapsLoaded] = useState(false);
	const [bgColorAnimation] = useState(new Animated.Value(0));
	const [formFadeInAnimation] = useState(new Animated.Value(0));
	const [mapsFadeInAnimation] = useState(new Animated.Value(0));

	const { sendEmailForm, loading, error } = useEmailJs();

	useEffect(() => {
		fadeInWhiteBackground();
		updateEnabledNavBg(true);
		updateSplashLoaded(true);
		updateSplashVideoLoaded(true);
		updateCurrentScreen(ScreenComponent.Contact);
	}, []);

	useEffect(() => {
		if (mapsLoaded) fadeInMaps();
	}, [mapsLoaded]);

	const fadeInWhiteBackground = () => {
		Animated.timing(bgColorAnimation, {
			toValue: 1,
			duration: SlowAnimationTime,
			useNativeDriver: false,
		}).start(() => fadeInContent());
	};

	const fadeInContent = () => {
		Animated.timing(formFadeInAnimation, {
			toValue: 1,
			duration: SlowAnimationTime,
			useNativeDriver: false,
		}).start();
	};

	const fadeInMaps = () => {
		Animated.timing(mapsFadeInAnimation, {
			toValue: 1,
			duration: FastAnimationTime,
			delay: 500,
			useNativeDriver: false,
		}).start();
	};

	const BasicInfo = () => {
		const whatsappDeepLink = "https://wa.me/2784 620 9118"; // Replace with your actual WhatsApp number

		const handleWhatsAppClick = () => {
			// You can add additional logic here if needed
			// For example, you might want to track the click event or perform some other action
			Linking.openURL(whatsappDeepLink);
		};

		return (
			<Container
				component="main"
				maxWidth="md"
			>
				<Grid
					container
					marginTop={12}
					marginBottom={4}
					alignItems={"center"}
					flexDirection={"column"}
					spacing={1}
				>
					<Grid
						item
						textAlign={"center"}
					>
						<Text style={styles.title}>Quick Info</Text>
					</Grid>
					<Grid
						item
						sx={{ mb: 2 }}
						textAlign={"center"}
					>
						<Text style={styles.subtitle}>
							{"Here's some quick information to get in touch with us"}
						</Text>
					</Grid>
				</Grid>
				<Grid
					container
					marginBottom={12}
					spacing={4}
					justifyContent={"center"}
				>
					<Grid item>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<Image
								style={{ width: 20, aspectRatio: 1 / 1, marginRight: 12 }}
								source={require("../../assets/images/icons/telephone.png")}
							/>
							<Text style={[styles.subtitle, { color: "black" }]}>{`084 620 9118`}</Text>
						</View>
					</Grid>
					<Grid
						item
						display={{ xs: "none", sm: "flex" }}
					>
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<Image
								style={{ width: 20, aspectRatio: 1 / 1, marginRight: 12 }}
								source={require("../../assets/images/icons/mail.png")}
							/>
							<Text style={[styles.subtitle, { color: "black" }]}>swantish@schwin.co.za</Text>
						</View>
					</Grid>
					<Grid
						item
						display={{ xs: "none", sm: "flex" }}
					>
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<Image
								style={{ width: 20, aspectRatio: 1 / 1, marginRight: 12 }}
								source={require("../../assets/images/icons/location.png")}
							/>
							<Text style={[styles.subtitle, { color: "black" }]}>Durban</Text>
						</View>
					</Grid>
					<Grid
						item
						display={{ xs: "none", sm: "flex" }}
					>
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<Image
								style={{ width: 20, aspectRatio: 1 / 1, marginRight: 12 }}
								source={require("../../assets/images/icons/whatsapp.png")}
							/>
							<Text
								style={[styles.subtitle, { color: "black", textDecorationLine: "underline" }]}
								onPress={handleWhatsAppClick}
							>
								Whatsapp
							</Text>
						</View>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" />
			</Container>
		);
	};

	const ContactForm = () => {
		const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const formData = new FormData(event.currentTarget);

			// Call the sendEmailForm function from the useEmailJs hook
			await sendEmailForm(formData);
		};

		return (
			<Container
				component="main"
				maxWidth="md"
			>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						xs={12}
						marginY={8}
					>
						<Box
							component="form"
							onSubmit={handleSubmit}
						>
							<Grid
								container
								mb={4}
								alignItems={"center"}
								flexDirection={"column"}
								spacing={1}
							>
								<Grid
									item
									textAlign={"center"}
								>
									<Text style={styles.title}>Want us to contact you?</Text>
								</Grid>
								<Grid
									item
									sx={{ mb: 2 }}
									textAlign={"center"}
								>
									<Text style={styles.subtitle}>
										{"Complete the form below\nWe'll be sure to contact you"}
									</Text>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										size="small"
										name="firstName"
										required
										fullWidth
										id="firstName"
										label="First Name"
										sx={{ input: { color: "black" } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										size="small"
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										sx={{ input: { color: "black" } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										size="small"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										sx={{ input: { color: "black" } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										size="small"
										fullWidth
										id="number"
										label="Contact Number"
										name="number"
										sx={{ input: { color: "black" } }}
									/>
								</Grid>
								{/* New field for additional information */}
								<Grid
									item
									xs={12}
								>
									<BasicTextField
										size="medium"
										fullWidth
										id="additionalInfo"
										label="Additional Information"
										name="additionalInfo"
										multiline={true}
										sx={{ input: { color: "black" } }}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
							>
								<Grid item>
									<BasicButton
										title={"Send"}
										type="submit"
										style={{ marginTop: 50, minWidth: 200, height: 55 }}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Divider orientation="horizontal" />
					</Grid>
					<Grid
						item
						xs={12}
						marginTop={8}
						marginBottom={14}
					>
						{/* Map iframe here */}
						<Box>
							{/* Map content ... */}
							<Grid
								container
								mb={4}
								alignItems={"center"}
								flexDirection={"column"}
								spacing={1}
							>
								<Grid
									item
									textAlign={"center"}
								>
									<Text style={styles.title}>Looking for us?</Text>
								</Grid>
								<Grid
									item
									sx={{ mb: 2 }}
									textAlign={"center"}
								>
									<Text style={styles.subtitle}>
										{"Have a look at our maps for a better view,\nyou can even get directions."}
									</Text>
								</Grid>
							</Grid>
							{!mapsLoaded && (
								<ActivityIndicator
									color={"grey"}
									size={"large"}
								/>
							)}
							<Animated.View style={{ opacity: mapsFadeInAnimation }}>
								<iframe
									src={
										"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110715.53344156723!2d30.908551536639568!3d-29.868298995890154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7aa0001bc61b7%3A0xcca75546c4aa6e81!2sDurban!5e0!3m2!1sen!2sza!4v1700952164481!5m2!1sen!2sza"
									}
									style={{ borderRadius: 12, borderWidth: 0, width: "100%", height: 350 }}
									onLoad={() => {
										setMapsLoaded(true);
									}}
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</Animated.View>
						</Box>
					</Grid>
				</Grid>
			</Container>
		);
	};

	return (
		<Animated.ScrollView
			style={{
				backgroundColor: bgColorAnimation.interpolate({
					inputRange: [0, 1],
					outputRange: ["rgba(255, 255, 255, 0.0)", "rgba(255, 255, 255, 1)"],
				}),
			}}
			contentContainerStyle={styles.container}
		>
			<Animated.View
				style={{
					opacity: formFadeInAnimation,
				}}
			>
				<BasicInfo />
				<ContactForm />
				<Footer />
			</Animated.View>
		</Animated.ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
	},
	title: {
		fontSize: 46,
		fontWeight: "400",
		fontFamily: "primaryFont",
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 18,
		fontWeight: "300",
		fontFamily: "secondaryFont",
		lineHeight: 32,
		textAlign: "center",
		color: "gray",
	},
});
