import { create } from "zustand";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { DefaultRoute } from "../../models/enums/DefaultRoute";

type State = {
	splashLoaded: boolean;
	splashVideoLoaded: boolean;
	enabledNavBg: boolean;
	defaultAuthRoute: DefaultRoute;
	currentScreen: ScreenComponent;
	screenList: ScreenComponent[];
	navItemList: ScreenComponent[];
};

type Action = {
	updateDefaultRoute: (data: State["defaultAuthRoute"]) => void;
	updateCurrentScreen: (data: State["currentScreen"]) => void;
	updateScreenList: (data: State["screenList"]) => void;
	updateSplashLoaded: (data: State["splashLoaded"]) => void;
	updateSplashVideoLoaded: (data: State["splashVideoLoaded"]) => void;
	updateEnabledNavBg: (data: State["enabledNavBg"]) => void;
};

export const navigationStore = create<State & Action>((set) => ({
	splashLoaded: false,
	splashVideoLoaded: false,
	enabledNavBg: false,
	defaultAuthRoute: DefaultRoute.Register,
	currentScreen: ScreenComponent.AboutUs,
	screenList: [ScreenComponent.AboutUs, ScreenComponent.OurFleet, ScreenComponent.Contact],
	navItemList: [ScreenComponent.AboutUs, ScreenComponent.OurFleet, ScreenComponent.Contact],

	updateSplashLoaded: (data) =>
		set(() => ({
			splashLoaded: data,
		})),

	updateSplashVideoLoaded: (data) =>
		set(() => ({
			splashVideoLoaded: data,
		})),

	updateEnabledNavBg: (data) =>
		set(() => ({
			enabledNavBg: data,
		})),

	updateScreenList: (data) =>
		set(() => ({
			screenList: data,
		})),

	updateCurrentScreen: (data) =>
		set(() => ({
			currentScreen: data,
		})),

	updateDefaultRoute: (data) =>
		set(() => ({
			defaultAuthRoute: data,
		})),
}));
